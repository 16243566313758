@media (max-width: 1649px) {
  .masonry-item {
    height: 239px;
    &.h-2 {
      height: 478px;
    }
  }
  .company-logo__image {
    margin-bottom: 50px;
  }
  .company-services-list-item__name {
      font-size: 16px;
      letter-spacing: -0.7px;
  }
  .company-services-list-item.active .company-services-list-item__name {
    font-size: 21px;
    letter-spacing: unset;
    line-height: 22px;
  }

  .content p, .p {
    font-size: 16px;
  }

  .container-small {
    padding: 0;
  }

  .masonry {
    //height: 956px;
  }

  .masonry-item .masonry-item-content__title {
    font-size: 27px;
  }
  .masonry-item .masonry-item-content__text {
    font-size: 16px;
    line-height: 18px;
  }
  .masonry-item .masonry-item-content__link {
    font-size: 16px;
  }
  .masonry-item .masonry-item-fullview {
    padding: 10px 35px;
    font-size: 32px;
    line-height: 36px;
  }
  .masonry-item .masonry-item-fullview .btn {
    margin-top: 20px;
  }
  .partners .square-flip, .partners .square-not-flip {
    min-height: 356px;
  }
  .partners .square-container2 {
    padding: 40px 20px 36px 30px;
  }
  .partners-block-item__description p, .partners-block-item__description ul, .partners-block-item__description li, .partners-block-item__description span {
    line-height: 22px;
  }
  .partners .square-not-flip .square-container {
    padding: 40px;
  }
  .content .h3, .content h3, .h3, h3 {
    font-size: 27px;
  }
  .btn, .content .btn {
    font-size: 15px;
  }

  .footer-form {
    background-color: #2d3e50;
    //padding-left: 7%;
    padding-top: 6%;
    padding-bottom: 2%;
    color: #fff;
    padding-right: 40px;
  }
  .footer-contacts {
    //padding-left: 7%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .footer-social {
    justify-content: center;
    padding: 35px 25px;
  }
  .footer-form .site-form__label {
    margin-right: 15px;
    font-size: 20px;
  }
  .footer-form .site-form-checkbox-text, .footer-form .site-form-checkbox-text a {
    font-size: 16px;
  }
  .footer-form .site-form-element-wrapper {
    position: relative;
    margin-bottom: 45px;
  }
}

@media (max-width: 1199px) {
  .masonry-item {
    height: 201px;
    &.h-2 {
      height: 402px;
    }
  }
  .col-lg-0 {
    display: none;
  }
  .company {
    margin-bottom: 35px;
  }
  .header {
    padding: 15px;
  }
  .masonry-item .masonry-item-content__title {
    font-size: 21px;
  }
  .partners .square-flip, .partners .square-not-flip {
    min-height: 296px;
  }
  .masonry-item .masonry-item-fullview {
    padding: 10px 30px;
    font-size: 24px;
    line-height: 28px;
  }
  .content p, .p {
    line-height: 24px;
  }
  .partners-block-item__description {
    height: 200px;
  }
  .partners .square-container {
    padding: 26px;
  }
  .partners .square-not-flip .square-container {
    padding: 26px;
  }
  .masonry-item .masonry-item-fullview .btn {
    margin-top: 12px;
  }
  .footer-social {
      padding: 12px 25px 5px;
  }
  .footer-form {
    //padding-left: 4%;
    padding-top: 4%;
    padding-bottom: 1%;
    padding-right: 30px;
  }
  .partners .square-container2 {
    padding: 25px 15px 25px 20px;
  }
  .company-description {
    padding: 4% 5%;
  }
  .masonry {
    //height: 804px;
    margin-bottom: 55px;
  }
  .footer-contacts,
  .footer-form {
    margin-right: 0;
  }
  .partners {
    margin-bottom: 45px;
  }
  .footer {
    margin-bottom: 65px;
  }
  .company-services-list {
    margin-left: 0;
    border-bottom: 1px solid #eee;
  }
}

@media (max-width: 991px) {
  .company-logo__image {
      margin-bottom: 20px;
  }
  .masonry-item:nth-child(1) {
    order: 1;
  }
  .masonry-item:nth-child(2) {
    order: 2;
  }
  .masonry-item:nth-child(3) {
    order: 7;
  }
  .masonry-item:nth-child(4) {
    order: 4;
  }
  .masonry-item:nth-child(5) {
    order: 5;
  }
  .masonry-item:nth-child(6) {
    order: 6;
  }
  .masonry-item:nth-child(7) {
    order: 3;
  }
  .masonry-item:nth-child(8) {
    order: 8;
  }
  .masonry {
      height: auto;
      flex-direction: row;
  }
  .order-md-last {
    order: 2;
  }
  .partners .square-flip, .partners .square-not-flip {
    min-height: 335px;
  }
  .partners-block-item__description {
    height: 235px;
  }
  .content .h3, .content h3, .h3, h3 {
    font-size: 30px;
  }
  .footer-contacts__logo {
    text-align: center;
  }
  .masonry-item {
    height: 430px;
    width: 100%;
  }
  .masonry-item.h-2 {
    height: 430px;
  }
  .footer-contacts {
    padding: 2% 4%;
  }
  .center-md {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .header-callback {
    display: none;
  }
  .footer-contacts-phone__title {
    font-size: 16px;
    text-align: center;
  }
  .masonry-item {
    width: 100%;
  }
  .footer-contacts-phone__num {
    font-size: 18px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .footer-contacts__logo {
    margin-top: 10px;
    text-align: center;
  }
  .footer-contacts {
    padding: 4% 4%;
  }
  .company-description {
    padding: 6% 4%;
  }
  .footer-social-list-item__link svg {
    width: 50px;
    height: 50px;
  }
  .site-form-element-wrapper-flex label.error {
    top: 100%;
    transform: none;
  }
  .footer-form .site-form__label {
    margin-right: 10px;
    font-size: 16px;
}
  .site-form__input.error {
      padding-right: 0;
  }
  .content .site-form, .site-form {
    padding-top: 0;
  }
  .footer-form .site-form-element-wrapper {
    margin-bottom: 30px;
    text-align: left;
}
  .btn--transparent, .content .btn--transparent {
      padding: 10px 15px;
  }
  .footer-form {
    padding-left: 6%;
    padding-top: 10%;
    padding-bottom: 1%;
    padding-right: 6%;
  }
  .footer-form .site-form__input {
    font-size: 16px;
    width: 100%;
  }
  .footer-form .site-form__label {
    line-height: 20px;
    font-size: 16px;
    text-align: left;
  }
  .site-form-element-wrapper-flex {
    flex-wrap: wrap;
  }
  .site-form-row.site-form-row--checkbox {
      margin-bottom: 35px;
  }
  .footer-form .site-form__input {
    font-size: 16px;
  }
  .company-description {
    padding: 6%;
  }
  .col-last {
    order:2;
  }
  .footer {
    margin-bottom: 25px;
  }
}