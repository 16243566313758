/**
* header desktop
* -----------------------------------------------------------------------------
*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 84px;
  z-index: 999;
  transition: all ease-in-out .4s;
  display: flex;
  align-items: center;

  &.header--fixed {
    background: #f1f1f3;
  }

  &-menu {
    font-family: 'Muller', "Arial", sans-serif;
    color: black;
    font-size: 18px;
    font-weight: 300;
    line-height: 0;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 11;
    transition: left 300ms ease-in-out;
    display: none;

    @media screen and (max-width: 1023px) {
      display: inline-flex;
      order: 0;
    }

    &-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: 1023px) {
        justify-content: space-between;
      }

      & .header-menu-list {
        padding-left: 70px;
        visibility: hidden;
        @media screen and (max-width: 1023px) {
          display: none;
        }
      }
    }

    &__logo {
      display: flex;
      padding-left: 10px;
      @media screen and (max-width: 1023px) {
        padding-left: 0px;
        order: 2;
      }


      #spinner__logo {
        transform: rotate(0deg);
        -moz-transform-origin: 83470.5px 10404.5px;
        -ms-transform-origin: 83470.5px 10404.5px;
        -o-transform-origin: 83470.5px 10404.5px;
        -webkit-transform-origin: 83470.5px 10404.5px;
        transform-origin: 83470.5px 10404.5px;
        transition: transform 2s ease-in-out;
      }

      &:hover #spinner__logo {
        -moz-transform-origin: 83470.5px 10404.5px;
        -ms-transform-origin: 83470.5px 10404.5px;
        -o-transform-origin: 83470.5px 10404.5px;
        -webkit-transform-origin: 83470.5px 10404.5px;
        transform-origin: 83470.5px 10404.5px;
        transform: rotate(720deg);
      }

      svg {
        max-width: 182px;
        height: 44px;
        transition: .3s all ease-in-out;
        opacity: 0;
        @media screen and (max-width: 1023px) {
          max-width: 175px;
        }
        @media screen and (max-width: 767px) {
          max-width: 150px;
        }
      }

    }
  }

  &-phone {
    color: black;
    font-size: 15px;
    font-weight: 400;
    font-family: @alternative-font;
    text-decoration: none;
    transition: all .3s ease-in-out;
    &:hover {
      color: #0087c9;
    }

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  &-contacts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &-callback {
    font-size: 14px;
    margin-left: 30px;
    font-family: 'Muller', "Arial", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    //border: 1px solid #010101;
    max-width: 189px;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    &.btn--transparent {
      color: #000;
    }

    &:visited {
      color: #000;
    }

    &:hover {
      border-color: #eee;
      color: #fff;
      background-image: linear-gradient(to right, #1bcde8 0%, #048bfc 100%);
    }

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
}

.header-menu-list {
  max-height: 100%;
  height: 100%;
  padding-left: 5px;
  //overflow-y: auto;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.header-menu-list {
  &-item {
    &__link {
      font-family: @alternative-font;
      font-size: 17px;
      color: black;
      text-decoration: none;
      display: inline-block;
      transition: color 300ms ease;
      padding-right: 40px;
      line-height: 20px;
      font-weight: 400;
      &:hover,
      &.active {
        color: #0087c9;
      }

      &.active {
        color: #0087c9;
      }
    }
  }
}

.header-menu-wrapper {
  padding-left: 65px;
  padding-top: 115px;
  opacity: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  background-color: white;
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 11;
  transition: opacity 300ms ease, left 200ms ease, right 200ms ease;

  & .header-menu-list {
    @media screen and (max-width: 1023px) {
      display: flex;
      flex-direction: column;
    }
  }

  &.active {
    left: 0;
    opacity: 1;
  }
}

.go-back {
  position: relative;
  cursor: pointer;
  visibility: hidden;
  font-family: 'Muller', "Arial", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: black;
  text-decoration: none;
  display: inline-block;
  transition: color 300ms ease;

  &:hover,
  &.active {
    color: #0087c9;
  }

  &.active {
    color: #0087c9;
  }
}

.header-menu-overlay {
  position: fixed;
  opacity: 0;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 500ms ease;

  &.active {
    opacity: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.no-scroll {
  overflow: hidden;
}

.hamburger-menu {
  margin: 0 auto;
  height: 15px;
  width: 38px;
  position: relative;
  cursor: pointer;

  *,
  *:before {
    transition: .25s ease-in-out;
  }
}

.hamburger-menu {
  border-radius: 100%;
}

.hamburger-menu span {
  display: block;
  width: 70%;
  height: 2px;
  left: 15%;
  background: #0e0e0e;
  position: absolute;
}

.hamburger-menu span:nth-of-type(1) {
  top: 0px;
  left: auto;
  right: 15%;
}

.hamburger-menu span:nth-of-type(2) {
  top: 6px;
}

.hamburger-menu span:nth-of-type(2):before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #0e0e0e;
  width: 100%;
  height: 100%;
}

.hamburger-menu span:nth-of-type(3) {
  top: 12px;
  left: 15%;
  width: 42%;
}

.hamburger-menu.active span:nth-of-type(1),
.hamburger-menu.active span:nth-of-type(3) {
  width: 0%;
}

.hamburger-menu.active span:nth-of-type(2) {
  transform: rotate(45deg);
  background: #0087c9;
  width: 60%;
  left: 19%;
}

.hamburger-menu.active span:nth-of-type(2):before {
  transform: rotate(90deg);
  background: #0087c9;
}

/**
* header mobile start
* -----------------------------------------------------------------------------
*/
.header-mobile {
  /**
  * локальные переменные
  * переназначайте их как угодно и где угодно
  */
  @mobile-header-element-padding-left: 10px;
  /* отступ элементов слева */
  @mobile-header-element-padding-right: 10px;
  /* отступ элементов справа */

  align-items: center;
  height: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;

  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  &-menu-button-wrapper {
    display: none;
  }

  &-menu-inner-wrapper {
    flex-direction: column;
    display: flex;
    height: 100%;
    padding-bottom: 20px;
    overflow-y: scroll;
  }

  &-nav {
    margin-bottom: 30px;
  }

  &-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: calc(~"100vh -" 70px);
    background-color: @gray;

    & .btn {
      align-self: flex-start;
      min-height: 47px;
      margin-right: @mobile-header-element-padding-right;
      margin-left: @mobile-header-element-padding-left;
    }
  }

  &-content-wrapper {
    display: flex;
    align-items: center;
    padding-left: @mobile-header-element-padding-left;
  }

  &-logo-wrapper {
    height: 100%;
    margin-right: 30px;
  }

  &-logo {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 54px;
  }

  &__phone {
    position: relative;
    top: 2px;
    display: inline-block;
    color: @text-color;
    text-decoration: none;
    line-height: 1;

    &:active {
      color: @link-active;
    }
  }

  &-phone-wrapper {
    height: 100%;
  }

  &-nav-list {
    display: block;
  }

  &-nav-item {
    border-bottom: 1px solid @gray--darken;

    &:last-child {
      border-bottom: 0;
    }
  }

  &-nav__link {
    display: block;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: @mobile-header-element-padding-left;
    padding-right: @mobile-header-element-padding-right;

    line-height: 24px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;

    &.active {
      color: @link-hover;
    }
  }

  &__menu-button {
    display: block;
    padding: 0;
    border: 0;
    position: relative;
    width: 70px;
    height: 70px;

    background-color: @gray;
    text-decoration: none;

    &:focus {
      outline: 0;
    }

    & div {
      position: absolute;
      left: 50%;
      margin-top: 23px;
      margin-left: -15px;
      width: 30px;
      height: 2px;
      background-color: @text-color;
      transition: 0.3s transform ease;
    }

    & div:nth-child(1) {
      top: 0;
    }

    & div:nth-child(2) {
      top: 11px;
    }

    & div:nth-child(3) {
      top: 21px;
    }

    &.active {
      background-color: @gray;

      & div {
        &:nth-child(1) {
          transform: rotate(45deg);
          transition: 0.3s all ease;
          top: 10px;
        }

        &:nth-child(2) {
          opacity: 0;
          transition: 0.3s opacity ease;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          transition: 0.3s all ease;
          top: 10px;
        }
      }
    }
  }

  &-menu-phone-wrapper {
    margin-bottom: 40px;
    padding-left: @mobile-header-element-padding-left;
    padding-right: @mobile-header-element-padding-right;
  }

  &-menu__phone {
    position: relative;
    top: 2px;
    display: inline-block;
    color: #424141;
    text-decoration: none;
    line-height: 1;
  }
}

@media (max-width: 1024px) {
  .header-mobile {
    display: flex;
  }

  .header-inner-wrapper {
    display: none;
  }

  .header-mobile-menu-button-wrapper {
    display: block;
  }
}

// header mobile close

.company {
  margin-bottom: 56px;
  background: #fff;

  &-section {
    padding-top: 0;
  }

  &-description-wrap {
    padding-left: 10px;
    padding-right: 0;

    @media screen and (max-width: 1199px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &-description {
    padding: 45px 65px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: 1200px) {
      > .row {
        height: 100%;
      }
    }
  }

  &-logo__image {
    margin-bottom: 0;
    display: block;
    transition: all .3s ease-in-out;

    #spinner__logo {
      transform: rotate(0deg);
      transform-origin: 83470.5px 10404.5px;
      transition: transform 2s ease-in-out;
    }

    &:hover #spinner__logo {
      transform-origin: 83470.5px 10404.5px;
      transform: rotate(720deg);
    }

    svg {
      width: 250px;
      height: 61px;
      transition: all .3s ease-in-out;
    }
  }

  &-places__wrap {
    //background: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 45px 0;
  }

  &-places {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 34px;

    position: relative;
    color: #222222;

    //background-image: url(../img/svg-sprite/leaves.svg);
    //background-size: 87px;
    //background-repeat: no-repeat;
    //background-position: center;
    margin: 0 auto;
    width: 80%;

    &:before {
      content: '';
      background-image: url(../img/svg-sprite/leaves.svg);
      background-size: cover;
      width: 87px;
      height: 31px;
      display: block;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 50px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 25px;
      height: 4px;
      background-color: #0087c9;
      bottom: 5px;
      right: 0;
      left: 0;
      margin: auto;
    }


    &__note {
      font-size: 20px;
      position: relative;
      color: #222222;
      font-family: "ProximaNova", "Arial", sans-serif;
      font-weight: 300;
    }

    &__place {
      font-weight: 700;
    }

    &__desc {
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 15px;
      position: relative;
      text-align: center;
      color: #222222;
      font-family: "ProximaNova", "Arial", sans-serif;
      font-weight: 300;

    }

  }

  &-services-list-wrap {
    padding-left: 0;
    padding-right: 10px;
    @media screen and (max-width: 1199px) {
      padding-left: 10px;
    }
  }

  &-services-list {
    display: flex;
    height: 100%;
    margin-left: -10px;
    overflow: hidden;
    @media (min-width: 1024px) and (max-width: 1199px) {
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      height: auto;
      border-top: 1px solid #e1e1e1;
      border-left: 1px solid #e1e1e1;
    }

    &-item {
      flex-grow: 1;
      position: relative;
      height: 100%;
      padding: 0 1%;
      width: 20%;
      min-height: 638px;
      flex-direction: column;
      display: flex;
      justify-content: center;
      text-align: center;
      align-content: center;
      border-right: 1px solid #e1e1e1;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 100%);
      transition: all 300ms linear;
      cursor: pointer;
      text-decoration: none;
      @media (min-width: 1024px) and (max-width: 1200px) {
        min-height: 350px;
        width: 25%;
      }
      @media screen and (max-width: 1023px) {
        min-height: 300px;
        width: 50%;
        border-bottom: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;
      }
      @media screen and (max-width: 767px) {
        min-height: 200px;
        width: 100%;
        border-bottom: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;
      }

      &.active {
        @media (min-width: 1024px) and (max-width: 1200px) {
          min-height: 350px;
          width: 25%;
        }
        @media screen and (max-width: 1023px) {
          min-height: 300px;
          width: 50%;
          border-bottom: 1px solid #e1e1e1;
        }
        @media screen and (max-width: 767px) {
          min-height: 200px;
          width: 100%;
          border-bottom: 1px solid #e1e1e1;
        }
      }

      &:first-child {
        border-left: 1px solid #e1e1e1;
        @media screen and (max-width: 1023px) {
          border-left: unset;
        }
      }

      &:after {
        content: '';
        opacity: 0;
        bottom: -10%;
        transition: opacity 800ms ease-in, bottom 400ms ease-in;
      }

      &:hover {
        .company-services-list-item__full {
          transform: scale(1.05);
        }
      }

      .item-icon {
        width: 71px;
        height: 66px;
        margin-bottom: 6px;
        @media screen and (max-width: 767px) {
          margin-top: 0 !important;
        }
      }

      &__content {
        position: relative;
        z-index: 1;
        transition: margin-top 600ms ease;
        @media screen and (max-width: 767px) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &__name {
        color: #7f7f7f;
        font-size: 17px;
        font-family: "ProximaNova", "Arial", sans-serif;
        font-weight: 300;
        line-height: 19px;
        transition: font-size 300ms ease-in;
      }

      &__full {
        padding: 10px;
        transition: all 300ms ease-in-out;

        .icon {
          width: 30px;
          height: 30px;
        }
      }

      .company-services-list-item__about {
        display: none;
        cursor: pointer;
      }

      .company-services-list-item__full {
        display: block;
      }

      &.active {
        flex-grow: 2;
        width: 40%;
        padding: 0 5% 0 5%;
        text-align: left;
        text-decoration: none;
        @media screen and (max-width: 1200px) {
          padding: 0 1%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          opacity: 1;
          left: 0;
          width: 191%;
          z-index: 0;
          display: block;

          @media screen and (min-width: 1650px) {
            width: 122%;
          }
          @media screen and (max-width: 1199px) {
            display: none;
          }
        }

        &.company-services-list-item--1:after {
          background: url('../img/company-services-slide-1.png') bottom left no-repeat;
          background-size: contain;
          height: 48%;
        }

        &.company-services-list-item--2:after {
          background: url('../img/company-services-slide-2.png') bottom left no-repeat;
          background-size: contain;
          height: 64%;
        }

        &.company-services-list-item--3:after {
          background: url('../img/company-services-slide-3.png') bottom center no-repeat;
          background-size: contain;
          height: 55%;
          width: 144%;
        }

        &.company-services-list-item--4:after {
          background: url('../img/company-services-slide-4.png') bottom right no-repeat;
          background-size: contain;
          height: 58%;
          width: 100%;
        }

        .item-icon {
          path {
            fill: #fff !important;
          }
        }

        .company-services-list-item__content {
          margin-top: -140px;
          @media screen and (max-width: 1200px) {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .company-services-list-item__name {
          color: white;
          font-size: 25px;
          font-weight: 300;
          line-height: 28px;
          margin-bottom: 15px;
        }

        background-image: linear-gradient(to top, #2ba1fa 0%, #3cd4ec 100%);

        .company-services-list-item__about {
          display: inline-block;
          color: white;
          font-family: "ProximaNova", "Arial", sans-serif;
          font-size: 17px;
          font-weight: 300;
          line-height: 30px;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        .company-services-list-item__full {
          display: none;
        }
      }
    }
  }
}

.masonry {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;

  &-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &:last-child {
      @media (min-width:769px) and (max-width: 1023px) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
  &-last--l, &-last--r {
    @media (min-width:769px) and (max-width: 1023px) {
      width: 50%;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }
  &-last--l {
    order: 1;
    @media (min-width:769px) and (max-width: 1023px) {
      order: 2;
    }
  }
  &-last--l {
    order: 2;
    @media (min-width:769px) and (max-width: 1023px) {
      order: 1;
    }
  }
}

.masonry-item {
  display: block;
  background-size: cover;
  background-position: center;
  height: 338px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.h-2 {
    height: 676px;
    width: 100%;
  }

  .masonry-item-fullview {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 40px;
    display: flex;
    color: white;
    font-family: 'Muller', "Arial", sans-serif;
    font-size: 39px;
    line-height: 42px;
    font-weight: 300;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    transition: opacity 300ms ease-in, bottom 300ms ease-in;

      & + .masonry-item-overlay {
        bottom: 0;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background-image: linear-gradient(to top, black 0%, transparent 100%);
      }

    .btn--transparent:hover,
    .content .btn--transparent:hover {
      border-color: transparent;
    }

    .btn {
      margin-top: 15px;
    }
  }

  .masonry-item-content {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 5px 30px;
    z-index: 1;
    max-height: 100px;
    transition: opacity 300ms ease-in, bottom 300ms ease-in;

    &__title {
      color: white;
      font-family: 'Muller', "Arial", sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 1;
      padding-bottom: 10px;
    }

    &__text {
      color: white;
      font-family: 'ProximaNova', "Arial", sans-serif;
      font-size: 17px;
      font-weight: 300;
      margin-bottom: 15px;
      line-height: 21px;
    }

    &__link {
      color: white;
      font-family: "ProximaNova", "Arial", sans-serif;
      font-size: 17px;
      font-weight: 300;
      line-height: 21px;
      text-decoration: underline;
      display: inline-flex;
      align-items: center;

      .icon {
        width: 15px;
        height: 15px;
        fill: #fff;
        margin-left: 5px;
        transition: margin-left 100ms ease-out;
      }

      &:hover {
        text-decoration: none;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .masonry-item-overlay {
    opacity: 0;
    bottom: -50%;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 300ms ease-in, bottom 200ms ease-in;
    background-image: linear-gradient(to top, black 0%, transparent 100%);
  }

  .masonry-item-video {
    //opacity: 0;
  }

  .masonry-item-anim {
    opacity: 0;
    transition: opacity 300ms ease-in;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background-size: cover;
  }
  @media screen and (max-width: 1023px) {
    .masonry-item-anim {
      opacity: 1;
    }

    .masonry-item-content {
      opacity: 1;
      bottom: 30px;
      @media screen and (max-width: 400px) {
        bottom: 80px;
      }
    }

    .masonry-item-overlay {
      opacity: 1;
      bottom: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background-image: linear-gradient(to top, black 0%, transparent 100%);
    }
  }

  &:hover {
    .masonry-item-anim {
      opacity: 1;
    }

    .masonry-item-content {
      opacity: 1;
      bottom: 30px;
      @media screen and (max-width: 400px) {
        bottom: 80px;
      }
    }

    .masonry-item-overlay {
      opacity: 1;
      bottom: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background-image: linear-gradient(to top, black 0%, transparent 100%);
    }
  }
}

.partners {
  margin-bottom: 95px;

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-block {
    width: 100%;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  &-block-item__image {
    margin: 0;
    max-width: 350px;
    max-height: 215px;
    display: inline-block;
    text-align: center;
    overflow: hidden;
  }

  &-block-item__image img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    display: inline-block;
  }

  &-block-item__title {
    position: relative;
    text-align: center;
    color: white;
    font-family: 'ProximaNova', "Arial", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 20px;
    z-index: 5;
    text-transform: uppercase;
  }

  &-block-item__description {

    p,
    ul,
    li,
    span {
      color: white !important;
      font-family: 'ProximaNova', "Arial", sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    font-weight: 300;
    position: relative;
    z-index: 5;
    height: 225px;
    overflow-y: auto;
    color: white !important;
    font-family: 'ProximaNova', "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }

  .square-flip,
  .square-not-flip {
    -webkit-perspective: 1000px;
    -ms-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform: perspective(1000px);
    -ms-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    float: left;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: -webkit-filter 250ms ease;
    transition: filter 250ms ease;
    transition: filter 250ms ease, -webkit-filter 250ms ease;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .square-flip:hover,
  .square-not-flip:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }

  .square-flip,
  .square-not-flip {
    width: 100%;
    float: left;
    min-height: 393px;
  }

  .square-not-flip {
    cursor: auto;
    filter: none;

    .square-container {
      padding: 50px;
      text-align: left;
    }
  }

  .square,
  .square2 {
    width: 100%;
    height: 100%;
  }

  .square {
    background-size: cover;
    background-position: center center;
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    overflow: hidden;
    position: absolute;
    top: 0;
    background: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .square-flip .square {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
  }

  .square-flip.rotated .square {
    -webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .square2 {
    background-size: cover;
    background-position: center center;
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .square-flip .square2 {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
  }

  .square-flip.rotated .square2 {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .square-container {
    padding: 40px;
    text-align: center;
    position: relative;
    top: 50%;
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transform: translateY(-50%) translateX(0px) scale(1);
    -ms-transform: translateY(-50%) translateX(0px) scale(1);
    transform: translateY(-50%) translateX(0px) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
  }

  .square-flip.rotated .square-container {
    -webkit-transform: translateY(-50%) translateX(-650px) scale(0.88);
    -ms-transform: translateY(-50%) translateX(-650px) scale(0.88);
    transform: translateY(-50%) translateX(-650px) scale(0.88);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .square-container2 {
    padding: 70px 20px 50px 30px;
    position: relative;
    top: 50%;
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    -ms-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
    height: 100%;
  }

  .square-flip.rotated .square-container2 {
    -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    -ms-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-size: cover;
  }

  .square-flip.rotated {
    filter: grayscale(0%);
  }

  .overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 3;
    top: 0;
    opacity: 0.59;
    background-image: linear-gradient(to top, #299df9 0%, #39d0eb 100%);
  }

  .align-center {
    margin: 0 auto;
  }
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  opacity: 0.46;
  background-color: white;
  border-radius: 7px;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.6;
    background-color: white;
  }
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  opacity: 0.21;
  background-color: white;
  border-radius: 7px;
  width: 14px;
}

.footer {
  margin-bottom: 85px;

  &-form {

    &-col {
      padding-right: 0;
      @media screen and (max-width: 1023px) {
        padding-right: 10px;
      }
    }

    .h2 {
      margin-bottom: 37px;
    }

    ::-webkit-input-placeholder {
      color: #fff;
      font-size: 19px;
      font-weight: 300;
    }

    ::-moz-placeholder {
      color: #fff;
      font-size: 19px;
      font-weight: 300;
    }

    /* Firefox 19+ */

    :-moz-placeholder {
      color: #fff;
      font-size: 19px;
      font-weight: 300;
    }

    /* Firefox 18- */

    :-ms-input-placeholder {
      color: #fff;
      font-size: 19px;
      font-weight: 300;
    }

    background-color: #2d3e50;
    padding-left: 65px;
    padding-top: 8%;
    padding-bottom: 10%;
    color: #fff;
    padding-right: 80px;
    @media screen and (max-width: 1023px) {
      padding-left: 4%;
    }

    .h2,
    .h3,
    span,
    a,
    .p,
    p,
    ul,
    li {
      color: #fff;
    }

    .site-form-element-wrapper {
      position: relative;
      margin-bottom: 55px;
    }

    .site-form {
      padding-left: 0;
    }
    .site-form__button-submit {
      max-width: 220px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .site-form__label {
      width: auto;
      margin-right: 20px;
      color: white;
      font-family: "ProximaNova", "Arial", sans-serif;
      font-size: 21px;
      font-weight: 300;
      line-height: 25px;
    }

    .site-form__input {
      flex-grow: 1;
      width: auto;
      height: 24px;
      border-top: none;
      border-left: none;
      border-right: none;
      color: white;
      font-family: "ProximaNova", "Arial", sans-serif;
      font-size: 19px;
      font-weight: 300;
      background: transparent;
      border-bottom-color: rgba(241, 241, 243, 0.5);
    }

    .site-form-checkbox-text {
      margin-bottom: 0;
      color: white;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;

      a {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: white;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-contacts {
    padding-left: 65px;
    padding-top: 4%;
    padding-bottom: 3%;
    background: #fff;
    padding-right: 60px;
    @media screen and (max-width: 1023px) {
      padding-left: 4%;
    }

    &-phone {
      display: inline-block;
      text-align: left;
      @media (min-width:1px) and (max-width: 767px) {
        text-align: center;
      }

      &__title {
        color: black;
        font-family: "ProximaNova", "Arial", sans-serif;
        font-weight: 300;
        font-size: 18px;
        @media (min-width:1200px) and (max-width: 1649px) {
          font-size: 16px;
        }
        @media (min-width:1px) and (max-width: 1199px) {
          font-size: 14px;
        }
      }

      &__num {
        color: black;
        font-family: "ProximaNova", "Arial", sans-serif;
        font-weight: 300;
        text-decoration: none;
        font-size: 21px;
        @media (min-width:1200px) and (max-width: 1649px) {
          font-size: 18px;
        }
        @media (min-width:1px) and (max-width: 1199px) {
          font-size: 16px;
        }
      }
    }

    &__logo {
      display: block;
      margin-top: 6px;

      img {
        max-width: 182px;
        max-height: 448pxx;
      }
    }
  }

  &-social-col {
    display: flex;
    flex-grow: 1;
    padding-left: 0;
    @media screen and (max-width: 1023px) {
      padding-left: 10px;
    }
  }

  &-social {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 35px 50px;
    background: url('../img/footer-social-block-bg.jpg') ~'center/cover no-repeat';

    &-list {
      display: flex;
      align-items: center;

      &-item {
        &__link {
          display: block;
          margin: 0px 3px;

          svg {
            width: 60px;
            height: 60px;
          }

          &:hover svg {
            circle {
              stroke-dasharray: 30.4;
              stroke-dashoffset: -300;
              animation: svgCircleOffsetAnimation 4s linear 0.01s infinite;
            }
          }
        }
      }
    }
  }
}

.col-4 .site-form-element-wrapper {
  margin-bottom: 0;
}

.site-form-row.site-form-row--checkbox {
  margin-bottom: 0;

  #agreement-error {
    margin-left: 32px;
  }

  & > .site-form-checkbox-wrapper {
    padding-top: 4px;
    display: flex;
    flex-direction: row;
  }

  .site-form__label-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: 3px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 17px;
    height: 17px;
    min-width: 17px;
    border-radius: 50%;
    border: none;
    background-color: #f6f6f6;
    background-color: #ececec;
    padding: 3px;
    cursor: pointer;
    margin-right: 15px;
    transition: 0.15s border-color ease-in;
  }

  .site-form__icon-checked {
    width: 12px;
    height: 19px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #0ca2f5;
    fill: none;
    transition: 0.15s opacity ease-in;
    opacity: 0;
  }
}

.site-form__input.error {
  border-color: #d04035 !important;
  padding-right: 200px;
}

.site-form-element-wrapper-flex {
  display: flex;
  align-items: center;
  position: relative;

  label.error {
    position: absolute;
    color: #d04035;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    left: auto;
  }
}

.btn--primary,
.content .btn--primary {
  outline: none;
}

.middle-align {
  align-items: center;
}

.between-position {
  justify-content: space-between;
}

.end-align {
  text-align: right;
}

@keyframes svgCircleOffsetAnimation {
  0% {
    stroke-dashoffset: -300;
  }

  25% {
    stroke-dashoffset: -150;
  }

  50% {
    stroke-dashoffset: 0;
  }

  75% {
    stroke-dashoffset: 150;
  }

  100% {
    stroke-dashoffset: 300;
  }
}

/**
*/

/**
* project start
* -----------------------------------------------------------------------------
*/

.project {

  &-info {

    &-section {
      margin-bottom: 40px;
    }

    &__top {

      &_wrapper {
        height: 100%;

        background-color: #f5f5f5;
        border-right: 1px solid #e9e9e9;

        padding-top: 38px;
        padding-bottom: 30px;
        padding-left: 50px;
        padding-right: 50px;

        @media (min-width: 1200px) and (max-width: 1600px) {
          padding-top: 20px;
          padding-bottom: 16px;
          padding-left: 20px;
          padding-right: 20px;
        }

        @media (min-width: 1px) and (max-width: 1199px) {
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 10px;
          padding-right: 10px;
        }

        &.dark {
          background-color: #e9e9e9;
        }

        &.term {
          padding-left: 70px;

          @media (max-width: 1199px) {
            padding-left: 10px;
            border-bottom: 1px solid #e9e9e9;
          }
        }

        &.external {
        }

        &.builder {
          padding-right: 70px;
        }
      }

      &_inner {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 579px) {
          flex-direction: column;
        }

      }

      &_item {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;

        width: 50%;
        max-width: 50%;
        flex-basis: 50%;

        @media (min-width: 1200px) and (max-width: 1600px) {
          flex-direction: column;
        }

        @media (max-width: 579px) {
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
        }

        & .property {
          margin-right: 3px;
        }

        & .value {
          font-weight: 500;
        }
      }

    }

    &__main {

      &_wrapper {
        height: 100%;

        border-right: 1px solid #e9e9e9;

        padding-top: 38px;
        padding-bottom: 30px;
        padding-left: 50px;
        padding-right: 50px;

        @media (max-width: 1199px) {
          padding-right: 10px;
          padding-left: 10px;
        }

        @media (max-width: 1024px) {
          padding-top: 16px;
          padding-bottom: 16px;
        }

        @media (max-width: 767px) {
          border-right: none;
        }

        &.about {
          padding-left: 70px;

          @media (max-width: 1199px) {
            padding-left: 10px;
          }

          @media (max-width: 767px) {
            border-bottom: 1px solid #e9e9e9;
          }
        }

        &.features {
          border-right: none;

          padding-left: 30px;
          padding-right: 0;

          @media (max-width: 1199px) {
            padding-left: 10px;
          }

          @media (max-width: 1024px) {
            padding-right: 10px;
          }

        }
      }

      &_inner {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;

        padding-top: 50px;

        @media (max-width: 767px) {
          padding-top: 20px;
        }

      }

      &_item {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 20%;
        max-width: 20%;
        flex-basis: 20%;

        padding-left: 5px;
        padding-right: 5px;

        margin-bottom: 26px;

        @media (min-width: 580px) and (max-width: 1400px) {
          width: 25%;
          max-width: 25%;
          flex-basis: 25%;
        }

        @media (min-width: 420px) and (max-width: 579px) {
          width: 33.3%;
          max-width: 33.3%;
          flex-basis: 33.3%;
        }

        @media (min-width: 1px) and (max-width: 419px) {
          width: 50%;
          max-width: 50%;
          flex-basis: 50%;
        }

        &-icon {
          & svg {
            width: 70px;
            height: 70px;
          }

          & img {
            display: block;

            width: 70px;
            height: 70px;

            margin-bottom: 5px;
          }
        }

        &-caption {
          font-size: 14px;
          text-align: center;
        }

      }

    }
  }
}

/**
* project stop
* -----------------------------------------------------------------------------
*/

/**
* about-top start
* -----------------------------------------------------------------------------
*/

@about-top-info-bgc: #fafafa; // цвет фона текстового блока
@about-top-info-pt: 90px; // верхний отступ текстового блока
@about-top-info-pb: 80px; // нижний отступ текстового блока
@about-top-info-pl: 70px; // левый отступ текстового блока
@about-top-info-pr: 30px; // правый отступ текстового блока

@about-top-logo-bgc: #f0f0f0; // цвет фона логотипа
@about-top-logo-pt: 53px; // верхний отступ логотипа
@about-top-logo-pb: 43px; // нижний отступ логотипа
@about-top-logo-pl: 20px; // левый отступ логотипа
@about-top-logo-pr: 20px; // правый отступ логотипа

.about-top {

  &__info {
    background-color: @about-top-info-bgc;

    padding-top: @about-top-info-pt;
    padding-bottom: @about-top-info-pb;
    padding-left: @about-top-info-pl;
    padding-right: @about-top-info-pr;

    @media (max-width: 1023px) {
      order: 2;

      padding-top: 20px;
      padding-bottom: 20px;

    }

    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__logo {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: @about-top-logo-bgc;

    padding-top: @about-top-logo-pt;
    padding-bottom: @about-top-logo-pb;
    padding-left: @about-top-logo-pl;
    padding-right: @about-top-logo-pr;

    @media (max-width: 1023px) {
      order: 1;

      padding-top: 20px;
      padding-bottom: 20px;
    }

    &_wrapper {
      position: relative;

      & img {
        width: 100%;
        max-width: 347px;
      }
    }
  }
}

/**
* counter start
* -----------------------------------------------------------------------------
*/

@counter-color: #fff; // цвет текста счетчика
@counter-pt: 120px; // верхний отступ секции счетчика
@counter-pb: 100px; // нижний отступ секции счетчика
@counter-number-font-size: 45px; // кегль числа счетчика
@counter-number-font-weight: 500; // толщина числа счетчика
@counter-caption-font-size: 18px; // кегль описания счетчика
@counter-caption-font-weight: 500; // толщина описания счетчика
@counter-mb: 20px;

.counter {
  color: @counter-color;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: @counter-mb;
  padding-top: @counter-pt;
  padding-bottom: @counter-pb;

  overflow: hidden;

  @media (max-width: 1023px) {
    padding-top: 30px;
    padding-bottom: 25px;
  }

  &__item {
    text-align: center;

    animation-duration: 1.2s;

    &_number {
      font-size: @counter-number-font-size;
      font-weight: @counter-number-font-weight;
    }

    &_caption {
      font-size: @counter-caption-font-size;
      font-weight: @counter-caption-font-weight;
    }

  }
}


.metadata-warning {
  width: 100%;
  min-width: 320px;
  color: #fff;
  background-color: @primary-color;
  padding: 0.75rem 1.75rem 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid darken(@primary-color, 15);
  border-radius: 0.25rem;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  margin: auto;
  display: block;
  z-index: 100;

  & button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    outline: none;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    position: absolute;
    background: transparent;
    right: 10px;
    top: 5px;
    padding: 0;
    margin: 0;
    border: none;

    &:hover {
      color: #000;
      text-decoration: none;
      opacity: 0.75;
    }
  }
}


.pnlm-about-msg {
  display: none !important;
}
.pannellum-popup__close {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 30px;
  height: 30px;
  background-image: url('../img/svg-sprite/close.svg#close');
  background-position: center;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: all .05s ease-in-out;
  z-index: 9999;
}
.pannellum-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 99;
}
.pannellum-popup.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  & .pannellum-popup__close {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }
}
#portfolio {
  padding-top: 94px;
  margin-top: -74px;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}
/**
* counter stop
* -----------------------------------------------------------------------------
*/

/**
* about-top stop
* -----------------------------------------------------------------------------
*/