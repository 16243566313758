/**
*
* site custom css
*/
/* variables */
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
/**
* Это основной стилевой файл
*/
/**
* header desktop
* -----------------------------------------------------------------------------
*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 84px;
  z-index: 999;
  transition: all ease-in-out 0.4s;
  display: flex;
  align-items: center;
}
.header.header--fixed {
  background: #f1f1f3;
}
.header-menu {
  font-family: 'Muller', "Arial", sans-serif;
  color: black;
  font-size: 18px;
  font-weight: 300;
  line-height: 0;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 11;
  transition: left 300ms ease-in-out;
  display: none;
}
@media screen and (max-width: 1023px) {
  .header-menu {
    display: inline-flex;
    order: 0;
  }
}
.header-menu-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .header-menu-wrap {
    justify-content: space-between;
  }
}
.header-menu-wrap .header-menu-list {
  padding-left: 70px;
  visibility: hidden;
}
@media screen and (max-width: 1023px) {
  .header-menu-wrap .header-menu-list {
    display: none;
  }
}
.header-menu__logo {
  display: flex;
  padding-left: 10px;
}
@media screen and (max-width: 1023px) {
  .header-menu__logo {
    padding-left: 0px;
    order: 2;
  }
}
.header-menu__logo #spinner__logo {
  transform: rotate(0deg);
  -moz-transform-origin: 83470.5px 10404.5px;
  -ms-transform-origin: 83470.5px 10404.5px;
  -o-transform-origin: 83470.5px 10404.5px;
  -webkit-transform-origin: 83470.5px 10404.5px;
  transform-origin: 83470.5px 10404.5px;
  transition: transform 2s ease-in-out;
}
.header-menu__logo:hover #spinner__logo {
  -moz-transform-origin: 83470.5px 10404.5px;
  -ms-transform-origin: 83470.5px 10404.5px;
  -o-transform-origin: 83470.5px 10404.5px;
  -webkit-transform-origin: 83470.5px 10404.5px;
  transform-origin: 83470.5px 10404.5px;
  transform: rotate(720deg);
}
.header-menu__logo svg {
  max-width: 182px;
  height: 44px;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}
@media screen and (max-width: 1023px) {
  .header-menu__logo svg {
    max-width: 175px;
  }
}
@media screen and (max-width: 767px) {
  .header-menu__logo svg {
    max-width: 150px;
  }
}
.header-phone {
  color: black;
  font-size: 15px;
  font-weight: 400;
  font-family: "Muller", Helvetica, Tahoma, Arial, sans-serif;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.header-phone:hover {
  color: #0087c9;
}
@media screen and (max-width: 1023px) {
  .header-phone {
    display: none;
  }
}
.header-contacts {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-callback {
  font-size: 14px;
  margin-left: 30px;
  font-family: 'Muller', "Arial", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  max-width: 189px;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.header-callback.btn--transparent {
  color: #000;
}
.header-callback:visited {
  color: #000;
}
.header-callback:hover {
  border-color: #eee;
  color: #fff;
  background-image: linear-gradient(to right, #1bcde8 0%, #048bfc 100%);
}
@media screen and (max-width: 1023px) {
  .header-callback {
    display: none;
  }
}
.header-menu-list {
  max-height: 100%;
  height: 100%;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .header-menu-list {
    flex-direction: column;
  }
}
.header-menu-list-item__link {
  font-family: "Muller", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 17px;
  color: black;
  text-decoration: none;
  display: inline-block;
  transition: color 300ms ease;
  padding-right: 40px;
  line-height: 20px;
  font-weight: 400;
}
.header-menu-list-item__link:hover,
.header-menu-list-item__link.active {
  color: #0087c9;
}
.header-menu-list-item__link.active {
  color: #0087c9;
}
.header-menu-wrapper {
  padding-left: 65px;
  padding-top: 115px;
  opacity: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  background-color: white;
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 11;
  transition: opacity 300ms ease, left 200ms ease, right 200ms ease;
}
@media screen and (max-width: 1023px) {
  .header-menu-wrapper .header-menu-list {
    display: flex;
    flex-direction: column;
  }
}
.header-menu-wrapper.active {
  left: 0;
  opacity: 1;
}
.go-back {
  position: relative;
  cursor: pointer;
  visibility: hidden;
  font-family: 'Muller', "Arial", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: black;
  text-decoration: none;
  display: inline-block;
  transition: color 300ms ease;
}
.go-back:hover,
.go-back.active {
  color: #0087c9;
}
.go-back.active {
  color: #0087c9;
}
.header-menu-overlay {
  position: fixed;
  opacity: 0;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 500ms ease;
}
.header-menu-overlay.active {
  opacity: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.no-scroll {
  overflow: hidden;
}
.hamburger-menu {
  margin: 0 auto;
  height: 15px;
  width: 38px;
  position: relative;
  cursor: pointer;
}
.hamburger-menu *,
.hamburger-menu *:before {
  transition: 0.25s ease-in-out;
}
.hamburger-menu {
  border-radius: 100%;
}
.hamburger-menu span {
  display: block;
  width: 70%;
  height: 2px;
  left: 15%;
  background: #0e0e0e;
  position: absolute;
}
.hamburger-menu span:nth-of-type(1) {
  top: 0px;
  left: auto;
  right: 15%;
}
.hamburger-menu span:nth-of-type(2) {
  top: 6px;
}
.hamburger-menu span:nth-of-type(2):before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #0e0e0e;
  width: 100%;
  height: 100%;
}
.hamburger-menu span:nth-of-type(3) {
  top: 12px;
  left: 15%;
  width: 42%;
}
.hamburger-menu.active span:nth-of-type(1),
.hamburger-menu.active span:nth-of-type(3) {
  width: 0%;
}
.hamburger-menu.active span:nth-of-type(2) {
  transform: rotate(45deg);
  background: #0087c9;
  width: 60%;
  left: 19%;
}
.hamburger-menu.active span:nth-of-type(2):before {
  transform: rotate(90deg);
  background: #0087c9;
}
/**
* header mobile start
* -----------------------------------------------------------------------------
*/
.header-mobile {
  /**
  * локальные переменные
  * переназначайте их как угодно и где угодно
  */
  /* отступ элементов слева */
  /* отступ элементов справа */
  align-items: center;
  height: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.header-mobile-menu-button-wrapper {
  display: none;
}
.header-mobile-menu-inner-wrapper {
  flex-direction: column;
  display: flex;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: scroll;
}
.header-mobile-nav {
  margin-bottom: 30px;
}
.header-mobile-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #f6f6f6;
}
.header-mobile-menu .btn {
  align-self: flex-start;
  min-height: 47px;
  margin-right: 10px;
  margin-left: 10px;
}
.header-mobile-content-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.header-mobile-logo-wrapper {
  height: 100%;
  margin-right: 30px;
}
.header-mobile-logo {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 54px;
}
.header-mobile__phone {
  position: relative;
  top: 2px;
  display: inline-block;
  color: #424141;
  text-decoration: none;
  line-height: 1;
}
.header-mobile__phone:active {
  color: #931a28;
}
.header-mobile-phone-wrapper {
  height: 100%;
}
.header-mobile-nav-list {
  display: block;
}
.header-mobile-nav-item {
  border-bottom: 1px solid #adadad;
}
.header-mobile-nav-item:last-child {
  border-bottom: 0;
}
.header-mobile-nav__link {
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 24px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}
.header-mobile-nav__link.active {
  color: #048bfc;
}
.header-mobile__menu-button {
  display: block;
  padding: 0;
  border: 0;
  position: relative;
  width: 70px;
  height: 70px;
  background-color: #f6f6f6;
  text-decoration: none;
}
.header-mobile__menu-button:focus {
  outline: 0;
}
.header-mobile__menu-button div {
  position: absolute;
  left: 50%;
  margin-top: 23px;
  margin-left: -15px;
  width: 30px;
  height: 2px;
  background-color: #424141;
  transition: 0.3s transform ease;
}
.header-mobile__menu-button div:nth-child(1) {
  top: 0;
}
.header-mobile__menu-button div:nth-child(2) {
  top: 11px;
}
.header-mobile__menu-button div:nth-child(3) {
  top: 21px;
}
.header-mobile__menu-button.active {
  background-color: #f6f6f6;
}
.header-mobile__menu-button.active div:nth-child(1) {
  transform: rotate(45deg);
  transition: 0.3s all ease;
  top: 10px;
}
.header-mobile__menu-button.active div:nth-child(2) {
  opacity: 0;
  transition: 0.3s opacity ease;
}
.header-mobile__menu-button.active div:nth-child(3) {
  transform: rotate(-45deg);
  transition: 0.3s all ease;
  top: 10px;
}
.header-mobile-menu-phone-wrapper {
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.header-mobile-menu__phone {
  position: relative;
  top: 2px;
  display: inline-block;
  color: #424141;
  text-decoration: none;
  line-height: 1;
}
@media (max-width: 1024px) {
  .header-mobile {
    display: flex;
  }
  .header-inner-wrapper {
    display: none;
  }
  .header-mobile-menu-button-wrapper {
    display: block;
  }
}
.company {
  margin-bottom: 56px;
  background: #fff;
}
.company-section {
  padding-top: 0;
}
.company-description-wrap {
  padding-left: 10px;
  padding-right: 0;
}
@media screen and (max-width: 1199px) {
  .company-description-wrap {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.company-description {
  padding: 45px 65px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (min-width: 1200px) {
  .company-description > .row {
    height: 100%;
  }
}
.company-logo__image {
  margin-bottom: 0;
  display: block;
  transition: all 0.3s ease-in-out;
}
.company-logo__image #spinner__logo {
  transform: rotate(0deg);
  transform-origin: 83470.5px 10404.5px;
  transition: transform 2s ease-in-out;
}
.company-logo__image:hover #spinner__logo {
  transform-origin: 83470.5px 10404.5px;
  transform: rotate(720deg);
}
.company-logo__image svg {
  width: 250px;
  height: 61px;
  transition: all 0.3s ease-in-out;
}
.company-places__wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 45px 0;
}
.company-places {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  position: relative;
  color: #222222;
  margin: 0 auto;
  width: 80%;
}
.company-places:before {
  content: '';
  background-image: url(../img/svg-sprite/leaves.svg);
  background-size: cover;
  width: 87px;
  height: 31px;
  display: block;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 50px;
}
.company-places:after {
  content: '';
  position: absolute;
  width: 25px;
  height: 4px;
  background-color: #0087c9;
  bottom: 5px;
  right: 0;
  left: 0;
  margin: auto;
}
.company-places__note {
  font-size: 20px;
  position: relative;
  color: #222222;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-weight: 300;
}
.company-places__place {
  font-weight: 700;
}
.company-places__desc {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 15px;
  position: relative;
  text-align: center;
  color: #222222;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-weight: 300;
}
.company-services-list-wrap {
  padding-left: 0;
  padding-right: 10px;
}
@media screen and (max-width: 1199px) {
  .company-services-list-wrap {
    padding-left: 10px;
  }
}
.company-services-list {
  display: flex;
  height: 100%;
  margin-left: -10px;
  overflow: hidden;
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .company-services-list {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
}
@media screen and (max-width: 1023px) {
  .company-services-list {
    flex-wrap: wrap;
    height: auto;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
  }
}
.company-services-list-item {
  flex-grow: 1;
  position: relative;
  height: 100%;
  padding: 0 1%;
  width: 20%;
  min-height: 638px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  align-content: center;
  border-right: 1px solid #e1e1e1;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3) 0%, #ffffff 100%);
  transition: all 300ms linear;
  cursor: pointer;
  text-decoration: none;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .company-services-list-item {
    min-height: 350px;
    width: 25%;
  }
}
@media screen and (max-width: 1023px) {
  .company-services-list-item {
    min-height: 300px;
    width: 50%;
    border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
  }
}
@media screen and (max-width: 767px) {
  .company-services-list-item {
    min-height: 200px;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .company-services-list-item.active {
    min-height: 350px;
    width: 25%;
  }
}
@media screen and (max-width: 1023px) {
  .company-services-list-item.active {
    min-height: 300px;
    width: 50%;
    border-bottom: 1px solid #e1e1e1;
  }
}
@media screen and (max-width: 767px) {
  .company-services-list-item.active {
    min-height: 200px;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
  }
}
.company-services-list-item:first-child {
  border-left: 1px solid #e1e1e1;
}
@media screen and (max-width: 1023px) {
  .company-services-list-item:first-child {
    border-left: unset;
  }
}
.company-services-list-item:after {
  content: '';
  opacity: 0;
  bottom: -10%;
  transition: opacity 800ms ease-in, bottom 400ms ease-in;
}
.company-services-list-item:hover .company-services-list-item__full {
  transform: scale(1.05);
}
.company-services-list-item .item-icon {
  width: 71px;
  height: 66px;
  margin-bottom: 6px;
}
@media screen and (max-width: 767px) {
  .company-services-list-item .item-icon {
    margin-top: 0 !important;
  }
}
.company-services-list-item__content {
  position: relative;
  z-index: 1;
  transition: margin-top 600ms ease;
}
@media screen and (max-width: 767px) {
  .company-services-list-item__content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.company-services-list-item__name {
  color: #7f7f7f;
  font-size: 17px;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-weight: 300;
  line-height: 19px;
  transition: font-size 300ms ease-in;
}
.company-services-list-item__full {
  padding: 10px;
  transition: all 300ms ease-in-out;
}
.company-services-list-item__full .icon {
  width: 30px;
  height: 30px;
}
.company-services-list-item .company-services-list-item__about {
  display: none;
  cursor: pointer;
}
.company-services-list-item .company-services-list-item__full {
  display: block;
}
.company-services-list-item.active {
  flex-grow: 2;
  width: 40%;
  padding: 0 5% 0 5%;
  text-align: left;
  text-decoration: none;
  background-image: linear-gradient(to top, #2ba1fa 0%, #3cd4ec 100%);
}
@media screen and (max-width: 1200px) {
  .company-services-list-item.active {
    padding: 0 1%;
  }
}
.company-services-list-item.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  opacity: 1;
  left: 0;
  width: 191%;
  z-index: 0;
  display: block;
}
@media screen and (min-width: 1650px) {
  .company-services-list-item.active:after {
    width: 122%;
  }
}
@media screen and (max-width: 1199px) {
  .company-services-list-item.active:after {
    display: none;
  }
}
.company-services-list-item.active.company-services-list-item--1:after {
  background: url('../img/company-services-slide-1.png') bottom left no-repeat;
  background-size: contain;
  height: 48%;
}
.company-services-list-item.active.company-services-list-item--2:after {
  background: url('../img/company-services-slide-2.png') bottom left no-repeat;
  background-size: contain;
  height: 64%;
}
.company-services-list-item.active.company-services-list-item--3:after {
  background: url('../img/company-services-slide-3.png') bottom center no-repeat;
  background-size: contain;
  height: 55%;
  width: 144%;
}
.company-services-list-item.active.company-services-list-item--4:after {
  background: url('../img/company-services-slide-4.png') bottom right no-repeat;
  background-size: contain;
  height: 58%;
  width: 100%;
}
.company-services-list-item.active .item-icon path {
  fill: #fff !important;
}
.company-services-list-item.active .company-services-list-item__content {
  margin-top: -140px;
}
@media screen and (max-width: 1200px) {
  .company-services-list-item.active .company-services-list-item__content {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.company-services-list-item.active .company-services-list-item__name {
  color: white;
  font-size: 25px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 15px;
}
.company-services-list-item.active .company-services-list-item__about {
  display: inline-block;
  color: white;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: underline;
}
.company-services-list-item.active .company-services-list-item__about:hover {
  text-decoration: none;
}
.company-services-list-item.active .company-services-list-item__full {
  display: none;
}
.masonry {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.masonry-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (min-width: 769px) and (max-width: 1023px) {
  .masonry-col:last-child {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .masonry-last--l,
  .masonry-last--r {
    width: 50%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}
.masonry-last--l {
  order: 1;
}
@media (min-width: 769px) and (max-width: 1023px) {
  .masonry-last--l {
    order: 2;
  }
}
.masonry-last--l {
  order: 2;
}
@media (min-width: 769px) and (max-width: 1023px) {
  .masonry-last--l {
    order: 1;
  }
}
.masonry-item {
  display: block;
  background-size: cover;
  background-position: center;
  height: 338px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.masonry-item.h-2 {
  height: 676px;
  width: 100%;
}
.masonry-item .masonry-item-fullview {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px 40px;
  display: flex;
  color: white;
  font-family: 'Muller', "Arial", sans-serif;
  font-size: 39px;
  line-height: 42px;
  font-weight: 300;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  transition: opacity 300ms ease-in, bottom 300ms ease-in;
}
.masonry-item .masonry-item-fullview + .masonry-item-overlay {
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-image: linear-gradient(to top, black 0%, transparent 100%);
}
.masonry-item .masonry-item-fullview .btn--transparent:hover,
.masonry-item .masonry-item-fullview .content .btn--transparent:hover {
  border-color: transparent;
}
.masonry-item .masonry-item-fullview .btn {
  margin-top: 15px;
}
.masonry-item .masonry-item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 5px 30px;
  z-index: 1;
  max-height: 100px;
  transition: opacity 300ms ease-in, bottom 300ms ease-in;
}
.masonry-item .masonry-item-content__title {
  color: white;
  font-family: 'Muller', "Arial", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  padding-bottom: 10px;
}
.masonry-item .masonry-item-content__text {
  color: white;
  font-family: 'ProximaNova', "Arial", sans-serif;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 21px;
}
.masonry-item .masonry-item-content__link {
  color: white;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 21px;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}
.masonry-item .masonry-item-content__link .icon {
  width: 15px;
  height: 15px;
  fill: #fff;
  margin-left: 5px;
  transition: margin-left 100ms ease-out;
}
.masonry-item .masonry-item-content__link:hover {
  text-decoration: none;
}
.masonry-item .masonry-item-content__link:hover .icon {
  margin-left: 10px;
}
.masonry-item .masonry-item-overlay {
  opacity: 0;
  bottom: -50%;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease-in, bottom 200ms ease-in;
  background-image: linear-gradient(to top, black 0%, transparent 100%);
}
.masonry-item .masonry-item-anim {
  opacity: 0;
  transition: opacity 300ms ease-in;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background-size: cover;
}
@media screen and (max-width: 1023px) {
  .masonry-item .masonry-item-anim {
    opacity: 1;
  }
  .masonry-item .masonry-item-content {
    opacity: 1;
    bottom: 30px;
  }
  .masonry-item .masonry-item-overlay {
    opacity: 1;
    bottom: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-image: linear-gradient(to top, black 0%, transparent 100%);
  }
}
@media screen and (max-width: 1023px) and screen and (max-width: 400px) {
  .masonry-item .masonry-item-content {
    bottom: 80px;
  }
}
.masonry-item:hover .masonry-item-anim {
  opacity: 1;
}
.masonry-item:hover .masonry-item-content {
  opacity: 1;
  bottom: 30px;
}
@media screen and (max-width: 400px) {
  .masonry-item:hover .masonry-item-content {
    bottom: 80px;
  }
}
.masonry-item:hover .masonry-item-overlay {
  opacity: 1;
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-image: linear-gradient(to top, black 0%, transparent 100%);
}
.partners {
  margin-bottom: 95px;
}
.partners .row {
  margin-left: -10px;
  margin-right: -10px;
}
.partners .col-1,
.partners .col-2,
.partners .col-3,
.partners .col-4,
.partners .col-5,
.partners .col-6,
.partners .col-7,
.partners .col-8,
.partners .col-9,
.partners .col-10,
.partners .col-11,
.partners .col-12,
.partners .col,
.partners .col-sm-1,
.partners .col-sm-2,
.partners .col-sm-3,
.partners .col-sm-4,
.partners .col-sm-5,
.partners .col-sm-6,
.partners .col-sm-7,
.partners .col-sm-8,
.partners .col-sm-9,
.partners .col-sm-10,
.partners .col-sm-11,
.partners .col-sm-12,
.partners .col-sm,
.partners .col-md-1,
.partners .col-md-2,
.partners .col-md-3,
.partners .col-md-4,
.partners .col-md-5,
.partners .col-md-6,
.partners .col-md-7,
.partners .col-md-8,
.partners .col-md-9,
.partners .col-md-10,
.partners .col-md-11,
.partners .col-md-12,
.partners .col-md,
.partners .col-lg-1,
.partners .col-lg-2,
.partners .col-lg-3,
.partners .col-lg-4,
.partners .col-lg-5,
.partners .col-lg-6,
.partners .col-lg-7,
.partners .col-lg-8,
.partners .col-lg-9,
.partners .col-lg-10,
.partners .col-lg-11,
.partners .col-lg-12,
.partners .col-lg,
.partners .col-xl-1,
.partners .col-xl-2,
.partners .col-xl-3,
.partners .col-xl-4,
.partners .col-xl-5,
.partners .col-xl-6,
.partners .col-xl-7,
.partners .col-xl-8,
.partners .col-xl-9,
.partners .col-xl-10,
.partners .col-xl-11,
.partners .col-xl-12,
.partners .col-xl {
  padding-left: 10px;
  padding-right: 10px;
}
.partners-block {
  width: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.partners-block-item__image {
  margin: 0;
  max-width: 350px;
  max-height: 215px;
  display: inline-block;
  text-align: center;
  overflow: hidden;
}
.partners-block-item__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  display: inline-block;
}
.partners-block-item__title {
  position: relative;
  text-align: center;
  color: white;
  font-family: 'ProximaNova', "Arial", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 20px;
  z-index: 5;
  text-transform: uppercase;
}
.partners-block-item__description {
  position: relative;
  z-index: 5;
  height: 225px;
  overflow-y: auto;
  color: white !important;
  font-family: 'ProximaNova', "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}
.partners-block-item__description p,
.partners-block-item__description ul,
.partners-block-item__description li,
.partners-block-item__description span {
  color: white !important;
  font-family: 'ProximaNova', "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}
.partners-block-item__description p:last-of-type {
  margin-bottom: 0;
}
.partners .square-flip,
.partners .square-not-flip {
  -webkit-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  float: left;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: -webkit-filter 250ms ease;
  transition: filter 250ms ease;
  transition: filter 250ms ease, -webkit-filter 250ms ease;
  cursor: pointer;
  margin-bottom: 20px;
}
.partners .square-flip:hover,
.partners .square-not-flip:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.partners .square-flip,
.partners .square-not-flip {
  width: 100%;
  float: left;
  min-height: 393px;
}
.partners .square-not-flip {
  cursor: auto;
  filter: none;
}
.partners .square-not-flip .square-container {
  padding: 50px;
  text-align: left;
}
.partners .square,
.partners .square2 {
  width: 100%;
  height: 100%;
}
.partners .square {
  background-size: cover;
  background-position: center center;
  transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  overflow: hidden;
  position: absolute;
  top: 0;
  background: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.partners .square-flip .square {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.partners .square-flip.rotated .square {
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.partners .square2 {
  background-size: cover;
  background-position: center center;
  transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.partners .square-flip .square2 {
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.partners .square-flip.rotated .square2 {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.partners .square-container {
  padding: 40px;
  text-align: center;
  position: relative;
  top: 50%;
  transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transform: translateY(-50%) translateX(0px) scale(1);
  -ms-transform: translateY(-50%) translateX(0px) scale(1);
  transform: translateY(-50%) translateX(0px) scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 2;
}
.partners .square-flip.rotated .square-container {
  -webkit-transform: translateY(-50%) translateX(-650px) scale(0.88);
  -ms-transform: translateY(-50%) translateX(-650px) scale(0.88);
  transform: translateY(-50%) translateX(-650px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.partners .square-container2 {
  padding: 70px 20px 50px 30px;
  position: relative;
  top: 50%;
  transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  -ms-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 2;
  height: 100%;
}
.partners .square-flip.rotated .square-container2 {
  -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  -ms-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-size: cover;
}
.partners .square-flip.rotated {
  filter: grayscale(0%);
}
.partners .overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 3;
  top: 0;
  opacity: 0.59;
  background-image: linear-gradient(to top, #299df9 0%, #39d0eb 100%);
}
.partners .align-center {
  margin: 0 auto;
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  opacity: 0.46;
  background-color: white;
  border-radius: 7px;
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover,
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:active,
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:focus {
  opacity: 0.6;
  background-color: white;
}
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  opacity: 0.21;
  background-color: white;
  border-radius: 7px;
  width: 14px;
}
.footer {
  margin-bottom: 85px;
}
.footer-form {
  /* Firefox 19+ */
  /* Firefox 18- */
  background-color: #2d3e50;
  padding-left: 65px;
  padding-top: 8%;
  padding-bottom: 10%;
  color: #fff;
  padding-right: 80px;
}
.footer-form-col {
  padding-right: 0;
}
@media screen and (max-width: 1023px) {
  .footer-form-col {
    padding-right: 10px;
  }
}
.footer-form .h2 {
  margin-bottom: 37px;
}
.footer-form ::-webkit-input-placeholder {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
}
.footer-form ::-moz-placeholder {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
}
.footer-form :-moz-placeholder {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
}
.footer-form :-ms-input-placeholder {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
}
@media screen and (max-width: 1023px) {
  .footer-form {
    padding-left: 4%;
  }
}
.footer-form .h2,
.footer-form .h3,
.footer-form span,
.footer-form a,
.footer-form .p,
.footer-form p,
.footer-form ul,
.footer-form li {
  color: #fff;
}
.footer-form .site-form-element-wrapper {
  position: relative;
  margin-bottom: 55px;
}
.footer-form .site-form {
  padding-left: 0;
}
.footer-form .site-form__button-submit {
  max-width: 220px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.footer-form .site-form__label {
  width: auto;
  margin-right: 20px;
  color: white;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 25px;
}
.footer-form .site-form__input {
  flex-grow: 1;
  width: auto;
  height: 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 19px;
  font-weight: 300;
  background: transparent;
  border-bottom-color: rgba(241, 241, 243, 0.5);
}
.footer-form .site-form-checkbox-text {
  margin-bottom: 0;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
.footer-form .site-form-checkbox-text a {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: white;
  text-decoration: underline;
}
.footer-form .site-form-checkbox-text a:hover {
  text-decoration: none;
}
.footer-contacts {
  padding-left: 65px;
  padding-top: 4%;
  padding-bottom: 3%;
  background: #fff;
  padding-right: 60px;
}
@media screen and (max-width: 1023px) {
  .footer-contacts {
    padding-left: 4%;
  }
}
.footer-contacts-phone {
  display: inline-block;
  text-align: left;
}
@media (min-width: 1px) and (max-width: 767px) {
  .footer-contacts-phone {
    text-align: center;
  }
}
.footer-contacts-phone__title {
  color: black;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 18px;
}
@media (min-width: 1200px) and (max-width: 1649px) {
  .footer-contacts-phone__title {
    font-size: 16px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .footer-contacts-phone__title {
    font-size: 14px;
  }
}
.footer-contacts-phone__num {
  color: black;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-weight: 300;
  text-decoration: none;
  font-size: 21px;
}
@media (min-width: 1200px) and (max-width: 1649px) {
  .footer-contacts-phone__num {
    font-size: 18px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .footer-contacts-phone__num {
    font-size: 16px;
  }
}
.footer-contacts__logo {
  display: block;
  margin-top: 6px;
}
.footer-contacts__logo img {
  max-width: 182px;
  max-height: 448pxx;
}
.footer-social-col {
  display: flex;
  flex-grow: 1;
  padding-left: 0;
}
@media screen and (max-width: 1023px) {
  .footer-social-col {
    padding-left: 10px;
  }
}
.footer-social {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 35px 50px;
  background: url('../img/footer-social-block-bg.jpg') center/cover no-repeat;
}
.footer-social-list {
  display: flex;
  align-items: center;
}
.footer-social-list-item__link {
  display: block;
  margin: 0px 3px;
}
.footer-social-list-item__link svg {
  width: 60px;
  height: 60px;
}
.footer-social-list-item__link:hover svg circle {
  stroke-dasharray: 30.4;
  stroke-dashoffset: -300;
  animation: svgCircleOffsetAnimation 4s linear 0.01s infinite;
}
.col-4 .site-form-element-wrapper {
  margin-bottom: 0;
}
.site-form-row.site-form-row--checkbox {
  margin-bottom: 0;
}
.site-form-row.site-form-row--checkbox #agreement-error {
  margin-left: 32px;
}
.site-form-row.site-form-row--checkbox > .site-form-checkbox-wrapper {
  padding-top: 4px;
  display: flex;
  flex-direction: row;
}
.site-form-row.site-form-row--checkbox .site-form__label-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  top: 3px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 17px;
  height: 17px;
  min-width: 17px;
  border-radius: 50%;
  border: none;
  background-color: #f6f6f6;
  background-color: #ececec;
  padding: 3px;
  cursor: pointer;
  margin-right: 15px;
  transition: 0.15s border-color ease-in;
}
.site-form-row.site-form-row--checkbox .site-form__icon-checked {
  width: 12px;
  height: 19px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #0ca2f5;
  fill: none;
  transition: 0.15s opacity ease-in;
  opacity: 0;
}
.site-form__input.error {
  border-color: #d04035 !important;
  padding-right: 200px;
}
.site-form-element-wrapper-flex {
  display: flex;
  align-items: center;
  position: relative;
}
.site-form-element-wrapper-flex label.error {
  position: absolute;
  color: #d04035;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  left: auto;
}
.btn--primary,
.content .btn--primary {
  outline: none;
}
.middle-align {
  align-items: center;
}
.between-position {
  justify-content: space-between;
}
.end-align {
  text-align: right;
}
@keyframes svgCircleOffsetAnimation {
  0% {
    stroke-dashoffset: -300;
  }
  25% {
    stroke-dashoffset: -150;
  }
  50% {
    stroke-dashoffset: 0;
  }
  75% {
    stroke-dashoffset: 150;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
/**
*/
/**
* project start
* -----------------------------------------------------------------------------
*/
.project-info-section {
  margin-bottom: 40px;
}
.project-info__top_wrapper {
  height: 100%;
  background-color: #f5f5f5;
  border-right: 1px solid #e9e9e9;
  padding-top: 38px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .project-info__top_wrapper {
    padding-top: 20px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .project-info__top_wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.project-info__top_wrapper.dark {
  background-color: #e9e9e9;
}
.project-info__top_wrapper.term {
  padding-left: 70px;
}
@media (max-width: 1199px) {
  .project-info__top_wrapper.term {
    padding-left: 10px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.project-info__top_wrapper.builder {
  padding-right: 70px;
}
.project-info__top_inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 579px) {
  .project-info__top_inner {
    flex-direction: column;
  }
}
.project-info__top_item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .project-info__top_item {
    flex-direction: column;
  }
}
@media (max-width: 579px) {
  .project-info__top_item {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}
.project-info__top_item .property {
  margin-right: 3px;
}
.project-info__top_item .value {
  font-weight: 500;
}
.project-info__main_wrapper {
  height: 100%;
  border-right: 1px solid #e9e9e9;
  padding-top: 38px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 1199px) {
  .project-info__main_wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 1024px) {
  .project-info__main_wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .project-info__main_wrapper {
    border-right: none;
  }
}
.project-info__main_wrapper.about {
  padding-left: 70px;
}
@media (max-width: 1199px) {
  .project-info__main_wrapper.about {
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .project-info__main_wrapper.about {
    border-bottom: 1px solid #e9e9e9;
  }
}
.project-info__main_wrapper.features {
  border-right: none;
  padding-left: 30px;
  padding-right: 0;
}
@media (max-width: 1199px) {
  .project-info__main_wrapper.features {
    padding-left: 10px;
  }
}
@media (max-width: 1024px) {
  .project-info__main_wrapper.features {
    padding-right: 10px;
  }
}
.project-info__main_inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .project-info__main_inner {
    padding-top: 20px;
  }
}
.project-info__main_item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 26px;
}
@media (min-width: 580px) and (max-width: 1400px) {
  .project-info__main_item {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 420px) and (max-width: 579px) {
  .project-info__main_item {
    width: 33.3%;
    max-width: 33.3%;
    flex-basis: 33.3%;
  }
}
@media (min-width: 1px) and (max-width: 419px) {
  .project-info__main_item {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
}
.project-info__main_item-icon svg {
  width: 70px;
  height: 70px;
}
.project-info__main_item-icon img {
  display: block;
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
}
.project-info__main_item-caption {
  font-size: 14px;
  text-align: center;
}
/**
* project stop
* -----------------------------------------------------------------------------
*/
/**
* about-top start
* -----------------------------------------------------------------------------
*/
.about-top__info {
  background-color: #fafafa;
  padding-top: 90px;
  padding-bottom: 80px;
  padding-left: 70px;
  padding-right: 30px;
}
@media (max-width: 1023px) {
  .about-top__info {
    order: 2;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .about-top__info {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.about-top__logo {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding-top: 53px;
  padding-bottom: 43px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1023px) {
  .about-top__logo {
    order: 1;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.about-top__logo_wrapper {
  position: relative;
}
.about-top__logo_wrapper img {
  width: 100%;
  max-width: 347px;
}
/**
* counter start
* -----------------------------------------------------------------------------
*/
.counter {
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
  padding-top: 120px;
  padding-bottom: 100px;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .counter {
    padding-top: 30px;
    padding-bottom: 25px;
  }
}
.counter__item {
  text-align: center;
  animation-duration: 1.2s;
}
.counter__item_number {
  font-size: 45px;
  font-weight: 500;
}
.counter__item_caption {
  font-size: 18px;
  font-weight: 500;
}
.metadata-warning {
  width: 100%;
  min-width: 320px;
  color: #fff;
  background-color: #33abff;
  padding: 0.75rem 1.75rem 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #0087e5;
  border-radius: 0.25rem;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  margin: auto;
  display: block;
  z-index: 100;
}
.metadata-warning button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  outline: none;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  position: absolute;
  background: transparent;
  right: 10px;
  top: 5px;
  padding: 0;
  margin: 0;
  border: none;
}
.metadata-warning button:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.pnlm-about-msg {
  display: none !important;
}
.pannellum-popup__close {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 30px;
  height: 30px;
  background-image: url('../img/svg-sprite/close.svg#close');
  background-position: center;
  background-size: contain;
  opacity: 0;
  visibility: hidden;
  transition: all 0.05s ease-in-out;
  z-index: 9999;
}
.pannellum-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 99;
}
.pannellum-popup.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.pannellum-popup.active .pannellum-popup__close {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}
#portfolio {
  padding-top: 94px;
  margin-top: -74px;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}
/**
* counter stop
* -----------------------------------------------------------------------------
*/
/**
* about-top stop
* -----------------------------------------------------------------------------
*/
/**
* файл для стилей для разных устройств
*/
@media (max-width: 1649px) {
  .masonry-item {
    height: 239px;
  }
  .masonry-item.h-2 {
    height: 478px;
  }
  .company-logo__image {
    margin-bottom: 50px;
  }
  .company-services-list-item__name {
    font-size: 16px;
    letter-spacing: -0.7px;
  }
  .company-services-list-item.active .company-services-list-item__name {
    font-size: 21px;
    letter-spacing: unset;
    line-height: 22px;
  }
  .content p,
  .p {
    font-size: 16px;
  }
  .container-small {
    padding: 0;
  }
  .masonry-item .masonry-item-content__title {
    font-size: 27px;
  }
  .masonry-item .masonry-item-content__text {
    font-size: 16px;
    line-height: 18px;
  }
  .masonry-item .masonry-item-content__link {
    font-size: 16px;
  }
  .masonry-item .masonry-item-fullview {
    padding: 10px 35px;
    font-size: 32px;
    line-height: 36px;
  }
  .masonry-item .masonry-item-fullview .btn {
    margin-top: 20px;
  }
  .partners .square-flip,
  .partners .square-not-flip {
    min-height: 356px;
  }
  .partners .square-container2 {
    padding: 40px 20px 36px 30px;
  }
  .partners-block-item__description p,
  .partners-block-item__description ul,
  .partners-block-item__description li,
  .partners-block-item__description span {
    line-height: 22px;
  }
  .partners .square-not-flip .square-container {
    padding: 40px;
  }
  .content .h3,
  .content h3,
  .h3,
  h3 {
    font-size: 27px;
  }
  .btn,
  .content .btn {
    font-size: 15px;
  }
  .footer-form {
    background-color: #2d3e50;
    padding-top: 6%;
    padding-bottom: 2%;
    color: #fff;
    padding-right: 40px;
  }
  .footer-contacts {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .footer-social {
    justify-content: center;
    padding: 35px 25px;
  }
  .footer-form .site-form__label {
    margin-right: 15px;
    font-size: 20px;
  }
  .footer-form .site-form-checkbox-text,
  .footer-form .site-form-checkbox-text a {
    font-size: 16px;
  }
  .footer-form .site-form-element-wrapper {
    position: relative;
    margin-bottom: 45px;
  }
}
@media (max-width: 1199px) {
  .masonry-item {
    height: 201px;
  }
  .masonry-item.h-2 {
    height: 402px;
  }
  .col-lg-0 {
    display: none;
  }
  .company {
    margin-bottom: 35px;
  }
  .header {
    padding: 15px;
  }
  .masonry-item .masonry-item-content__title {
    font-size: 21px;
  }
  .partners .square-flip,
  .partners .square-not-flip {
    min-height: 296px;
  }
  .masonry-item .masonry-item-fullview {
    padding: 10px 30px;
    font-size: 24px;
    line-height: 28px;
  }
  .content p,
  .p {
    line-height: 24px;
  }
  .partners-block-item__description {
    height: 200px;
  }
  .partners .square-container {
    padding: 26px;
  }
  .partners .square-not-flip .square-container {
    padding: 26px;
  }
  .masonry-item .masonry-item-fullview .btn {
    margin-top: 12px;
  }
  .footer-social {
    padding: 12px 25px 5px;
  }
  .footer-form {
    padding-top: 4%;
    padding-bottom: 1%;
    padding-right: 30px;
  }
  .partners .square-container2 {
    padding: 25px 15px 25px 20px;
  }
  .company-description {
    padding: 4% 5%;
  }
  .masonry {
    margin-bottom: 55px;
  }
  .footer-contacts,
  .footer-form {
    margin-right: 0;
  }
  .partners {
    margin-bottom: 45px;
  }
  .footer {
    margin-bottom: 65px;
  }
  .company-services-list {
    margin-left: 0;
    border-bottom: 1px solid #eee;
  }
}
@media (max-width: 991px) {
  .company-logo__image {
    margin-bottom: 20px;
  }
  .masonry-item:nth-child(1) {
    order: 1;
  }
  .masonry-item:nth-child(2) {
    order: 2;
  }
  .masonry-item:nth-child(3) {
    order: 7;
  }
  .masonry-item:nth-child(4) {
    order: 4;
  }
  .masonry-item:nth-child(5) {
    order: 5;
  }
  .masonry-item:nth-child(6) {
    order: 6;
  }
  .masonry-item:nth-child(7) {
    order: 3;
  }
  .masonry-item:nth-child(8) {
    order: 8;
  }
  .masonry {
    height: auto;
    flex-direction: row;
  }
  .order-md-last {
    order: 2;
  }
  .partners .square-flip,
  .partners .square-not-flip {
    min-height: 335px;
  }
  .partners-block-item__description {
    height: 235px;
  }
  .content .h3,
  .content h3,
  .h3,
  h3 {
    font-size: 30px;
  }
  .footer-contacts__logo {
    text-align: center;
  }
  .masonry-item {
    height: 430px;
    width: 100%;
  }
  .masonry-item.h-2 {
    height: 430px;
  }
  .footer-contacts {
    padding: 2% 4%;
  }
  .center-md {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .header-callback {
    display: none;
  }
  .footer-contacts-phone__title {
    font-size: 16px;
    text-align: center;
  }
  .masonry-item {
    width: 100%;
  }
  .footer-contacts-phone__num {
    font-size: 18px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .footer-contacts__logo {
    margin-top: 10px;
    text-align: center;
  }
  .footer-contacts {
    padding: 4% 4%;
  }
  .company-description {
    padding: 6% 4%;
  }
  .footer-social-list-item__link svg {
    width: 50px;
    height: 50px;
  }
  .site-form-element-wrapper-flex label.error {
    top: 100%;
    transform: none;
  }
  .footer-form .site-form__label {
    margin-right: 10px;
    font-size: 16px;
  }
  .site-form__input.error {
    padding-right: 0;
  }
  .content .site-form,
  .site-form {
    padding-top: 0;
  }
  .footer-form .site-form-element-wrapper {
    margin-bottom: 30px;
    text-align: left;
  }
  .btn--transparent,
  .content .btn--transparent {
    padding: 10px 15px;
  }
  .footer-form {
    padding-left: 6%;
    padding-top: 10%;
    padding-bottom: 1%;
    padding-right: 6%;
  }
  .footer-form .site-form__input {
    font-size: 16px;
    width: 100%;
  }
  .footer-form .site-form__label {
    line-height: 20px;
    font-size: 16px;
    text-align: left;
  }
  .site-form-element-wrapper-flex {
    flex-wrap: wrap;
  }
  .site-form-row.site-form-row--checkbox {
    margin-bottom: 35px;
  }
  .footer-form .site-form__input {
    font-size: 16px;
  }
  .company-description {
    padding: 6%;
  }
  .col-last {
    order: 2;
  }
  .footer {
    margin-bottom: 25px;
  }
}
